import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const RMACTLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Leondires"
    institution="RMA Connecticut"
    referralCode="RMACT"
    physicianURL="https://www.rmact.com/our-fertility-doctors/dr-mark-leondires"
  />
)

export default RMACTLandingPage
